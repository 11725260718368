import * as React from 'react'

import { Seo } from 'components/helpers'
import { DefaultLayout } from 'components/layouts'

import NotFoundView from 'views/NotFound'

const NotFoundPage = () => (
  <DefaultLayout>
    <Seo title="Página não encontrada" />
    <NotFoundView />
  </DefaultLayout>
)

export default NotFoundPage
