import * as React from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'

import { Block, Button, Text, Title } from 'components/shared'
import { Ribbon } from 'components/helpers'
import { InstagramFeed, Social } from 'components/app'

const NotFoundView: React.FC = () => (
  <>
    <Block tag="section" paddingTop="size88" paddingBottom="size48">
      <Container>
        <Block tag="header" marginBottom="size48">
          <Title
            size="h3"
            icon={{
              name: 'error-404',
              color: 'brown800',
              width: 'size20',
              height: 'size20',
              marginLeft: 'size48',
              marginRight: 'size8'
            }}
            appearance="section"
            marginRight="size12"
            uppercase
          >
            Erro 404
          </Title>
        </Block>
        <Block>
          <Text marginBottom={32}>
            O endereço que você acessou não foi encontrado.
          </Text>

          <Row>
            <Col xs={12} sm={4}>
              <Button as="link" to="/" title="Ir para o início">
                Ir para o início
              </Button>
            </Col>
          </Row>
        </Block>
      </Container>
    </Block>

    <Block height="size88" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="brown800" slogan="primary" angle={0.4} delay="-12s" />
    </Block>

    <Block tag="section" paddingTop="size48" overflow="hidden">
      <Container>
        <Block
          tag="header"
          display="flex"
          alignItems="center"
          paddingBottom={{
            xxxs: 'size48',
            sm: 'size64',
            lg: 'size96'
          }}
          marginBottom="size48"
        >
          <Title
            size="h3"
            icon={{
              name: 'like',
              color: 'brown800',
              width: 'size20',
              height: 'size20',
              marginLeft: 'size48',
              marginRight: 'size8'
            }}
            lineHeight={1}
            appearance="section"
            marginRight="size12"
            uppercase
          >
            Siga nossas redes
          </Title>
          <Social />
        </Block>
      </Container>
      <Block tag="article" bgColor="yellow400" transform="translateY(25%);">
        <Container>
          <Block transform="translateY(-50%);">
            <InstagramFeed />
          </Block>
        </Container>
      </Block>
    </Block>
  </>
)

export default NotFoundView
